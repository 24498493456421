import DocumentMeta from 'react-document-meta';
import Styles from './styles.module.css';
import { useHistory } from 'react-router-dom';

import React, { useState, useEffect, useRef } from 'react';
import firebase from 'firebase/compat/app';

import Header from "./header/index";
import Filter from "./search/index";

import Recruits from "./recruits/index";

import Logo from "./../../../image/icon/logo.png";
import { LuRefreshCcw } from "react-icons/lu";
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";

import Personality1 from "./../../../image/works/mbti/1.png";
import Personality2 from "./../../../image/works/mbti/2.png";
import Personality3 from "./../../../image/works/mbti/3.png";
import Personality4 from "./../../../image/works/mbti/4.png";
import Personality5 from "./../../../image/works/mbti/5.png";
import Personality6 from "./../../../image/works/mbti/6.png";
import Personality7 from "./../../../image/works/mbti/7.png";
import Personality8 from "./../../../image/works/mbti/8.png";

import Salon1 from "./../../../image/works/salon/1.png";
import Salon2 from "./../../../image/works/salon/2.png";
import Salon3 from "./../../../image/works/salon/3.png";
import Salon4 from "./../../../image/works/salon/4.png";
import Salon5 from "./../../../image/works/salon/5.png";
import Salon6 from "./../../../image/works/salon/6.png";
import Salon7 from "./../../../image/works/salon/7.png";
import Salon8 from "./../../../image/works/salon/8.png";



import { onSnapshot, collection, query, where, orderBy, } from 'firebase/firestore';

const WorksTop = () => {

  const history = useHistory();

  const [ recruitList, setRecruitList ] = useState([]);

  const meta = {
    title: 'memorii works（メモリーワークス） | 美容業界の新卒マッチングサービス',
    description: '美容室へ就職される美容学生へ向けて行きたい美容室が見つかる就活サイト、美容学生を採用したい方へ向けて新卒採用・管理が行えるサービス',
    canonical: 'https://memorii-works.com',
    meta: { charset: 'utf-8', }
  };

  const [ visible1, setVisible1 ] = useState(false);
  const [ visible2, setVisible2 ] = useState(false);
  const [ position1, setPosition1 ] = useState({ top: 0, left: 0 });
  const [ position2, setPosition2 ] = useState({ top: 0, left: 0 });

  const buttonRef1 = useRef(null);
  const buttonRef2 = useRef(null);
  const divRef1 = useRef(null);
  const divRef2 = useRef(null);

  const [ checkFirst, setCheckFirst ] = useState(false);

  const [ pref, setPref] = useState("");
  
  const [ characterScore, setCharacterScore] = useState([ 1, 1, 1 ]);


  useEffect(() => {

    if (!checkFirst) {
      search();
      setCheckFirst(true);
    }

    if (visible1 || visible2) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };

  }, [visible1]);

  const handleButtonClick1 = (event) => {
    const button = buttonRef1.current;
    if (button) {
      const rect = button.getBoundingClientRect();
      setPosition1({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
    setVisible1(!visible1);
  };

  const handleButtonClick2 = (event) => {
    const button = buttonRef2.current;
    if (button) {
      const rect = button.getBoundingClientRect();
      setPosition2({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
    setVisible2(!visible2);
  };

  const handleClickOutside = (event) => {
    if (
      (divRef1.current && !divRef1.current.contains(event.target) && buttonRef1.current && !buttonRef1.current.contains(event.target)) ||
      (divRef2.current && !divRef2.current.contains(event.target) && buttonRef2.current && !buttonRef2.current.contains(event.target))
    ) {
      setVisible1(false);
      setVisible2(false);
    }
  };

  const shuffleArray = (array) => {
    const cloneArray = [...array]

    for (let i = cloneArray.length - 1; i >= 0; i--) {
      let rand = Math.floor(Math.random() * (i + 1))
      // 配列の要素の順番を入れ替える
      let tmpStorage = cloneArray[i]
      cloneArray[i] = cloneArray[rand]
      cloneArray[rand] = tmpStorage
    }

    return cloneArray
  }

  const [ flip, setFlip ] = useState(false);
  const handleFrip = () => { flip ? setFlip(false) : setFlip(true)};

  // 性格診断
  const [ animate1, setAnimate1 ] = useState(false);
  const handleAnimationUp1 = () => { setAnimate1(true); setCharacterScore([ 1, 1, 5 ]);};

  const [ animate2, setAnimate2 ] = useState(false);
  const handleAnimationUp2 = () => { setAnimate2(true); setCharacterScore([ 1, 1, 1 ]);};
  const handleAnimationDown2 = () => { setAnimate1(false); setCharacterScore([ 1, 5, 1 ]);};

  const [ animate3, setAnimate3 ] = useState(false);
  const handleAnimationUp3 = () => { setAnimate3(true); setCharacterScore([ 1, 1, 5 ]);};
  const handleAnimationDown3 = () => { setAnimate2(false); setCharacterScore([ 1, 5, 5 ]);};

  const [ animate4, setAnimate4 ] = useState(false);
  const handleAnimationUp4 = () => { setAnimate4(true); setCharacterScore([ 1, 5, 1 ]);};
  const handleAnimationDown4 = () => { setAnimate3(false); setCharacterScore([ 5, 1, 1 ]);};

  const [ animate5, setAnimate5 ] = useState(false);
  const handleAnimationUp5 = () => { setAnimate5(true); setCharacterScore([ 1, 5, 5 ]);};
  const handleAnimationDown5 = () => { setAnimate4(false); setCharacterScore([ 5, 1, 5 ])};

  const [ animate6, setAnimate6 ] = useState(false);
  const handleAnimationUp6 = () => { setAnimate6(true); setCharacterScore([ 5, 1, 1 ]);};
  const handleAnimationDown6 = () => { setAnimate5(false); setCharacterScore([ 5, 5, 1 ]);};

  const [ animate7, setAnimate7 ] = useState(false);
  const handleAnimationUp7 = () => { setAnimate7(true); setCharacterScore([ 5, 1, 5 ]);};
  const handleAnimationDown7 = () => { setAnimate6(false); setCharacterScore([ 5, 5, 5 ]);};

  const [ animate8, setAnimate8 ] = useState(false);
  const handleAnimationDown8 = () => { setAnimate7(false); setCharacterScore([ 5, 5, 1 ]);};

  // タイプ別
  const [ animate2_1, setAnimate2_1 ] = useState(false);
  const handleAnimationUp2_1 = () => { setAnimate2_1(true); searchType("トレンドサロン");};

  const [ animate2_2, setAnimate2_2 ] = useState(false);
  const handleAnimationUp2_2 = () => { setAnimate2_2(true); searchType("有名サロン");};
  const handleAnimationDown2_2 = () => { setAnimate2_1(false); searchType("おしゃれサロン");};

  const [ animate2_3, setAnimate2_3 ] = useState(false);
  const handleAnimationUp2_3 = () => { setAnimate2_3(true); searchType("トレンドサロン");};
  const handleAnimationDown2_3 = () => { setAnimate2_2(false); searchType("ビューティーサロン");};

  const [ animate2_4, setAnimate2_4 ] = useState(false);
  const handleAnimationUp2_4 = () => { setAnimate2_4(true); searchType("おしゃれサロン");};
  const handleAnimationDown2_4 = () => { setAnimate2_3(false); searchType("オールラウンドサロン");};

  const [ animate2_5, setAnimate2_5 ] = useState(false);
  const handleAnimationUp2_5 = () => { setAnimate2_5(true); searchType("ビューティーサロン");};
  const handleAnimationDown2_5 = () => { setAnimate2_4(false); searchType("愛されサロン");};

  const [ animate2_6, setAnimate2_6 ] = useState(false);
  const handleAnimationUp2_6 = () => { setAnimate2_6(true); searchType("オールラウンドサロン");};
  const handleAnimationDown2_6 = () => { setAnimate2_5(false); searchType("密着サロン");};

  const [ animate2_7, setAnimate2_7 ] = useState(false);
  const handleAnimationUp2_7 = () => { setAnimate2_7(true); searchType("愛されサロン");};
  const handleAnimationDown2_7 = () => { setAnimate2_6(false); searchType("こだわりサロン");};

  const [ animate2_8, setAnimate2_8 ] = useState(false);
  const handleAnimationDown2_8 = () => { setAnimate2_7(false); searchType("密着サロン");};
  
  var hashTags = [
    "カットが得意",
    "カラーが得意",
    "カッコイイ施術多め",
    "ナチュラルな施術多め",
    "トレンド感のある施術多め",
    "SNS研修あり",
    "自社アカデミーあり",
    "動画研修制度あり",
    "セミナー参加費補助",
    "ウィッグ代補助",
    "社会保険完備",
    "完全週休2日制",
    "残業月20時間以内",
  ]

  const [ hashTagList, setHashTagList ] = useState([]);

  function search() {

    setRecruitList([]);

    const recruitsRef = collection(firebase.firestore(), 'recruits')

    var queryList = query(recruitsRef);

    const queryRef = query(queryList, where('status', "==", 1))
    const unsub = onSnapshot(queryRef, snapshot => {
      var recruits = [];
      snapshot.docs.forEach(doc => {
        recruits.push({...doc.data(), id: doc.id});
      })
      setRecruitList(shuffleArray(recruits));
    })
    return () => unsub();
  }

  // function arrayControl(value) {

  //   var list = [];

  //   if (!hashTagList.includes(value)) {
  //     list = [ ...hashTagList, value ];
  //     search(list);
  //   } else {
  //     list = hashTagList.filter((item) => item !== value);
  //     if (都道府県.includes(value)) {
  //       setPref("");
  //     }
  //     search(list);
  //   }

  //   const recruitsRef = collection(firebase.firestore(), 'recruits')

  //   var queryList = query(recruitsRef);
    
  //   list.forEach((tag) => {
  //     queryList = query(queryList, where(`recruit_searchTags.${tag}`, "==", true));
  //   });

  //   const queryRef = query(queryList, where('status', "==", 1))
  //   const unsub = onSnapshot(queryRef, snapshot => {
  //     var recruits = [];
  //     snapshot.docs.forEach(doc => {
  //       recruits.push({...doc.data(), id: doc.id});
  //     })
  //     setRecruitList(shuffleArray(recruits));
  //   });
    
  //   setHashTagList(list);
  //   return () => unsub();
  // }

  function searchType(value) {

    const recruitsRef = collection(firebase.firestore(), 'recruits')

    var queryList = query(recruitsRef);
    
    [ ...hashTagList, value ].forEach((tag) => {
      queryList = query(queryList, where(`recruit_searchTags.${tag}`, "==", true));
    });

    const queryRef = query(queryList, where('status', "==", 1))
    const unsub = onSnapshot(queryRef, snapshot => {
      var recruits = [];
      snapshot.docs.forEach(doc => {
        recruits.push({...doc.data(), id: doc.id});
      })
      setRecruitList(shuffleArray(recruits));
    });
    
    return () => unsub();
  }

  return (
    <>
    <DocumentMeta {...meta}>
      <Header setCharacterScore={setCharacterScore} />
      <Filter recruitList={recruitList} setRecruitList={setRecruitList} characterScore={characterScore} />
      <Recruits recruitList={recruitList} characterScore={characterScore} />
    </DocumentMeta>
    </>
  );
}

export default WorksTop;
