import Styles from './styles.module.css';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import DefaultImage from './../../../../image/works/default/recruit.png';

const WorksTop = (props) => {

  const history = useHistory();

  return (
    <>
    <div className={Styles.list}>
      {props.recruitList.length === 0 ?
        <h4 className={Styles.list_none}>指定した条件で美容室を検索しましたが、該当する美容室がございませんでした。</h4>
      : <></> }
      {props.recruitList.map((data, index) =>
        <div className={Styles.list_box} onClick={() => history.push(`/recruit/id=${data.id}`)}>
          <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/styles%2Fresize_images%2F${data['store_imageName'][0]}_1080x1080?alt=media&token=`}
          onError={e => {
            e.target.onError = null;
            e.target.src = DefaultImage;
          }}/>
          <p>マッチ率：{Math.ceil((
            ((4 - Math.abs(data['recruit_person1'] - props.characterScore[0])) / 4) + 
            ((4 - Math.abs(data['recruit_person2'] - props.characterScore[1])) / 4) +
            ((4 - Math.abs(data['recruit_person3'] - props.characterScore[2])) / 4) +
            ((4 - Math.abs(data['recruit_person4'] - props.characterScore[1])) / 4) +
            ((4 - Math.abs(data['recruit_person5'] - props.characterScore[2])) / 4)
          ) / 4 * 100)}％</p>
          <div>
            <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/companies_logo%2Fresize_images%2F${data['companyId']}_200x200?alt=media&token=`}
              onError={e => {
                e.target.onError = null;
                e.target.src = DefaultImage;
              }}/>
            <h4>{data['store_name']}</h4>
          </div>
          <h3>{data['store_title']}</h3>
        </div>
      )}
    </div>
    </>
  );
}

export default WorksTop;
