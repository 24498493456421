import Styles from './styles.module.css';
import firebase from 'firebase/compat/app';
import { useState } from 'react';
import { FaBookmark } from "react-icons/fa";
import { FaRegBookmark } from "react-icons/fa6";
import Details from './../../../package/info/index';
import { useHistory } from 'react-router-dom';
import Examination from './../../../package/info/mobile/evaluation/index';
import { LuClipboardEdit } from "react-icons/lu";
import DefaultImg from './../../../../../image/works/default.png';

const MemoriiWorksManageItems = (props) => {

  const history = useHistory();

  const [ recruitId, setRecruitId ] = useState('');
  const [ applicantId, setApplicantId ] = useState('');

  const [ examination, setExamination ] = useState([]);
  const [ examinationPanel, setExaminationPanel ] = useState([]);

  return (
    <>
    <div className={Styles.box}>

      <div className={Styles.box_item_1}
        onClick={() => { setRecruitId(props.data.recruitId); setApplicantId(props.data.id); }}>
        <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${props.data.uid}_500x500?alt=media&token=`} alt=''
          onError={e => {
            e.target.onError = null;
            e.target.src = DefaultImg;
          }}/>
        <div className={Styles.box_1_post}>
          <div>
            <div style={{backgroundColor: '#192651'}}></div>
            <p>{props.data.examination === 1 ? '1次選考' : props.data.examination === 2 ? '2次選考' : props.data.examination === 3 ? '3次選考' : props.data.examination === 4 ? '4次選考' : props.data.examination === 5 ? '5次選考' : props.data.examination === 6 ? '採用' : '不採用'}</p>
          </div>
          <h2>{props.data.name}</h2>
        </div>
      </div>

      <p className={Styles.box_item_2} onClick={() => { setRecruitId(props.data.recruitId); setApplicantId(props.data.id); }}>
        {props.data.sex}性
      </p>

      {/* {(props.data[`examList${props.data.examination}`] !== undefined &&
        props.data[`examList${props.data.examination}`].includes(props.userList.id)) || (props.data.examination === 6 || props.data.examination === 7) ? <div className={Styles.box_item_3}></div> :
        <LuClipboardEdit className={Styles.box_item_3} onClick={() => setExamination(props.data)} />
      } */}
      {props.data[`examList${props.data.examination}`] === undefined ? <div className={Styles.box_item_3}></div> :
      <>
        {props.data[`examList${props.data.examination}`].includes(props.userList.id) || 8 < props.data[`examList${props.data.examination}`].length ? <div className={Styles.box_item_3}></div> :
          <LuClipboardEdit className={Styles.box_item_3} onClick={() => setExamination(props.data)} />
        }
      </>
      }

      {!props.save ?
        <FaRegBookmark className={Styles.box_item_4} style={{color : '#222222'}} alt=''
          onClick={() => {
            firebase.firestore().collection('applicants').doc(props.data.id)
            .update({save: firebase.firestore.FieldValue.arrayUnion(props.userList.user_uid)});
          }}
        />
      : <></> }

      {props.save ?
        <FaBookmark className={Styles.box_item_4} style={{color : '#AD0200'}} alt=''
          onClick={() => {
            firebase.firestore().collection('applicants').doc(props.data.id)
            .update({save: firebase.firestore.FieldValue.arrayRemove(props.userList.user_uid)});
          }}
        />
      : <></> }

    </div>

    {examination.length !== 0 ?
      <div className={Styles.modal}>
        <div>
          <div className={Styles.modal_black} onClick={() => {setExamination([])}}></div>
          <div className={Styles.modal_contents}>
            <h4>審査しますか？</h4>
            <p>応募者1名に対して審査できる人数は8名までとなりますので、予め社内で審査担当者を検討してください。</p>
            <div>
              <button style={{backgroundColor : 'white', color : '#AD0200'}} onClick={() => setExamination([])}>キャンセル</button>
              <button style={{backgroundColor : '#AD0200', color : 'white'}} onClick={() => {setExaminationPanel(examination); setExamination([]);}}>審査する</button>
            </div>
          </div>
        </div>
      </div>
    : <></> }

    {recruitId !== '' && applicantId !== '' ?
      <Details recruitId={recruitId} applicantId={applicantId} setRecruitId={setRecruitId} setApplicantId={setApplicantId} userList={props.userList} navigation={props.navigation} setHistory={props.setHistory} />
    : <></> }

    {examinationPanel.length !== 0 ?
      <Examination applicantsList={examinationPanel} setApplicantsList={setExaminationPanel} userList={props.userList} />
    : <></> }

    </>
  );
}

export default MemoriiWorksManageItems;
