import Styles from './styles.module.css';
import React, { useState, useEffect } from 'react';
import firebase from "firebase/compat/app";
import { useHistory } from 'react-router-dom';
import DefaultImg from './../../../../image/works/default.png';

const MemoriiWorksMessage = (props) => {

  const history = useHistory();

  const [ uid, setUid ] = useState('');
  const [ name, setName ] = useState('');

  useEffect(() => {

    if (props.chat.length !== 0) {
      setUid(props.chat.chat_name.indexOf(props.companyList.id) === 0 ? props.chat.chat_name[1] : props.chat.chat_name[0]);
      setName(props.chat[`${props.chat.chat_name.indexOf(props.companyList.id) === 0 ? props.chat.chat_name[1] : props.chat.chat_name[0]}`][0]);

      console.log(props.chat);
    }
  }, []);

  // バッジをリセット
  function badgeUpdate(id) {
    var badge = {};
    var key = `${props.companyList.id}_badge`;
    badge[key] = 0;
    firebase.firestore().collection('chat').doc(id).update({ ...badge });
  }

  return (
    <>
      {props.progress ?
      <>
        {props.chatListStatus === 1 ?
          <div className={Styles.chat} onClick={() => badgeUpdate(props.chat.id)}>
            <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${uid}_200x200?alt=media&token=`} alt=''
              onError={e => {
                e.target.onError = null;
                e.target.src = DefaultImg;
                console.log(uid)
              }}/>
            <div className={Styles.chat_name}>
              <h5>{name}</h5>
              <p>{props.chat.chat_last}</p>
            </div>
            {props.chat[`${props.companyList.id}_badge`] !== 0 ?
              <div className={Styles.chat_badge}></div>
            : <></> }
          </div>
        : <></> }
        
        {props.chatListStatus === 2 && props.chat[`${props.companyList.id}_badge`] !== 0 ?
          <div className={Styles.chat} onClick={() => badgeUpdate(props.chat.id)}>
            <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${uid}_200x200?alt=media&token=`} alt=''
              onError={e => {
                e.target.onError = null;
                e.target.src = DefaultImg;
                console.log(uid)
              }}/>
            <div className={Styles.chat_name}>
              <h5>{name}</h5>
              <p>{props.chat.chat_last}</p>
            </div>
            {props.chat[`${props.companyList.id}_badge`] !== 0 ?
              <div className={Styles.chat_badge}></div>
            : <></> }
          </div>
        : <></> }
        
        {props.chatListStatus === 3 && !props.chat[`${props.companyList.id}_badge`] !== 0 ?
          <div className={Styles.chat} onClick={() => badgeUpdate(props.chat.id)}>
            <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${uid}_200x200?alt=media&token=`} alt=''
              onError={e => {
                e.target.onError = null;
                e.target.src = DefaultImg;
                console.log(uid)
              }}/>
            <div className={Styles.chat_name}>
              <h5>{name}</h5>
              <p>{props.chat.chat_last}</p>
            </div>
            {props.chat[`${props.companyList.id}_badge`] !== 0 ?
              <div className={Styles.chat_badge}></div>
            : <></> }
          </div>
        : <></> }
      </>
      :
      <>
        {props.includes ?
        <>
          {props.chatListStatus === 1 ?
            <div className={Styles.chat} onClick={() => badgeUpdate(props.chat.id)}>
              <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${uid}_200x200?alt=media&token=`} alt=''
              onError={e => {
                e.target.onError = null;
                e.target.src = DefaultImg;
                console.log(uid)
              }}/>
              <div className={Styles.chat_name}>
                <h5>{name}</h5>
                <p>{props.chat.chat_last}</p>
              </div>
              {props.chat[`${props.companyList.id}_badge`] !== 0 ?
                <div className={Styles.chat_badge}></div>
              : <></> }
            </div>
          : <></> }

          {props.chatListStatus === 2 && props.chat[`${props.companyList.id}_badge`] !== 0 ?
            <div className={Styles.chat} onClick={() => badgeUpdate(props.chat.id)}>
              <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${uid}_200x200?alt=media&token=`} alt=''
              onError={e => {
                e.target.onError = null;
                e.target.src = DefaultImg;
                console.log(uid)
              }}/>
              <div className={Styles.chat_name}>
                <h5>{name}</h5>
                <p>{props.chat.chat_last}</p>
              </div>
              {props.chat[`${props.companyList.id}_badge`] !== 0 ?
                <div className={Styles.chat_badge}></div>
              : <></> }
            </div>
          : <></> }

          {props.chatListStatus === 3 && !props.chat[`${props.companyList.id}_badge`] !== 0 ?
            <div className={Styles.chat} onClick={() => badgeUpdate(props.chat.id)}>
              <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${uid}_200x200?alt=media&token=`} alt=''
              onError={e => {
                e.target.onError = null;
                e.target.src = DefaultImg;
                console.log(uid)
              }}/>
              <div className={Styles.chat_name}>
                <h5>{name}</h5>
                <p>{props.chat.chat_last}</p>
              </div>
              {props.chat[`${props.companyList.id}_badge`] !== 0 ?
                <div className={Styles.chat_badge}></div>
              : <></> }
            </div>
          : <></> }
        </> : <></> }
      </>}
    </>
  );
}

export default MemoriiWorksMessage;