import Styles from './styles.module.css';
import { useHistory } from 'react-router-dom';

import React, { useState, useEffect, useRef } from 'react';
import firebase from 'firebase/compat/app';

import Logo from "./../../../../image/icon/logo.png";
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";

import Personality1 from "./../../../../image/works/mbti/1.png";
import Personality2 from "./../../../../image/works/mbti/2.png";
import Personality3 from "./../../../../image/works/mbti/3.png";
import Personality4 from "./../../../../image/works/mbti/4.png";
import Personality5 from "./../../../../image/works/mbti/5.png";
import Personality6 from "./../../../../image/works/mbti/6.png";
import Personality7 from "./../../../../image/works/mbti/7.png";
import Personality8 from "./../../../../image/works/mbti/8.png";
import { FaAppStoreIos } from "react-icons/fa";

import { IoFilterOutline } from "react-icons/io5";

import { onSnapshot, collection, query, where, orderBy, } from 'firebase/firestore';

const WorksTop = (props) => {

  const history = useHistory();

  const [ recruitList, setRecruitList ] = useState([]);

  const [ visible1, setVisible1 ] = useState(false);
  const [ visible2, setVisible2 ] = useState(false);

  const buttonRef1 = useRef(null);
  const buttonRef2 = useRef(null);
  const divRef1 = useRef(null);
  const divRef2 = useRef(null);

  const [ checkFirst, setCheckFirst ] = useState(false);

  const [ pref, setPref] = useState("");

  useEffect(() => {

    if (!checkFirst) {
      search();
      setCheckFirst(true);
    }

    if (visible1 || visible2) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };

  }, [visible1]);

  const handleClickOutside = (event) => {
    if (
      (divRef1.current && !divRef1.current.contains(event.target) && buttonRef1.current && !buttonRef1.current.contains(event.target)) ||
      (divRef2.current && !divRef2.current.contains(event.target) && buttonRef2.current && !buttonRef2.current.contains(event.target))
    ) {
      setVisible1(false);
      setVisible2(false);
    }
  };

  const shuffleArray = (array) => {
    const cloneArray = [...array]

    for (let i = cloneArray.length - 1; i >= 0; i--) {
      let rand = Math.floor(Math.random() * (i + 1))
      // 配列の要素の順番を入れ替える
      let tmpStorage = cloneArray[i]
      cloneArray[i] = cloneArray[rand]
      cloneArray[rand] = tmpStorage
    }

    return cloneArray
  }

  const [ flip, setFlip ] = useState(false);
  const handleFrip = () => { flip ? setFlip(false) : setFlip(true)};

  // 性格診断
  const [ animate1, setAnimate1 ] = useState(false);
  const handleAnimationUp1 = () => { setAnimate1(true); props.setCharacterScore([ 1, 1, 5 ]);};

  const [ animate2, setAnimate2 ] = useState(false);
  const handleAnimationUp2 = () => { setAnimate2(true); props.setCharacterScore([ 1, 1, 1 ]);};
  const handleAnimationDown2 = () => { setAnimate1(false); props.setCharacterScore([ 1, 5, 1 ]);};

  const [ animate3, setAnimate3 ] = useState(false);
  const handleAnimationUp3 = () => { setAnimate3(true); props.setCharacterScore([ 1, 1, 5 ]);};
  const handleAnimationDown3 = () => { setAnimate2(false); props.setCharacterScore([ 1, 5, 5 ]);};

  const [ animate4, setAnimate4 ] = useState(false);
  const handleAnimationUp4 = () => { setAnimate4(true); props.setCharacterScore([ 1, 5, 1 ]);};
  const handleAnimationDown4 = () => { setAnimate3(false); props.setCharacterScore([ 5, 1, 1 ]);};

  const [ animate5, setAnimate5 ] = useState(false);
  const handleAnimationUp5 = () => { setAnimate5(true); props.setCharacterScore([ 1, 5, 5 ]);};
  const handleAnimationDown5 = () => { setAnimate4(false); props.setCharacterScore([ 5, 1, 5 ])};

  const [ animate6, setAnimate6 ] = useState(false);
  const handleAnimationUp6 = () => { setAnimate6(true); props.setCharacterScore([ 5, 1, 1 ]);};
  const handleAnimationDown6 = () => { setAnimate5(false); props.setCharacterScore([ 5, 5, 1 ]);};

  const [ animate7, setAnimate7 ] = useState(false);
  const handleAnimationUp7 = () => { setAnimate7(true); props.setCharacterScore([ 5, 1, 5 ]);};
  const handleAnimationDown7 = () => { setAnimate6(false); props.setCharacterScore([ 5, 5, 5 ]);};

  const [ animate8, setAnimate8 ] = useState(false);
  const handleAnimationDown8 = () => { setAnimate7(false); props.setCharacterScore([ 5, 5, 1 ]);};

  const [ hashTagList, setHashTagList ] = useState([]);

  function search() {

    setRecruitList([]);

    const recruitsRef = collection(firebase.firestore(), 'recruits')

    var queryList = query(recruitsRef);

    const queryRef = query(queryList, where('status', "==", 1))
    const unsub = onSnapshot(queryRef, snapshot => {
      var recruits = [];
      snapshot.docs.forEach(doc => {
        recruits.push({...doc.data(), id: doc.id});
      })
      setRecruitList(shuffleArray(recruits));
    })
    return () => unsub();
  }

  function searchType(value) {

    const recruitsRef = collection(firebase.firestore(), 'recruits')

    var queryList = query(recruitsRef);
    
    [ ...hashTagList, value ].forEach((tag) => {
      queryList = query(queryList, where(`recruit_searchTags.${tag}`, "==", true));
    });

    const queryRef = query(queryList, where('status', "==", 1))
    const unsub = onSnapshot(queryRef, snapshot => {
      var recruits = [];
      snapshot.docs.forEach(doc => {
        recruits.push({...doc.data(), id: doc.id});
      })
      setRecruitList(shuffleArray(recruits));
    });
    
    return () => unsub();
  }

  return (
    <>
    <div className={Styles.app_download}>
      <div>
        <img src={Logo} />
        <p>memorii works</p>
      </div>
      <a href='https://apps.apple.com/jp/app/memorii-works/id1578278781'>
        <button>アプリを入手</button>
      </a>
    </div>

    <div className={Styles.header}>
      <div className={Styles.logo}>
        <img src={Logo} />
        <h4>memorii works</h4>
      </div>
      <div className={Styles.topSearch}>

        <div className={Styles.topSearch_hidden}>
          <div className={animate1 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
            style={animate1 ? {transform : "translateY(-80px)", backgroundColor : '#DB6262', zIndex : "10"} : 
              {transform : "translateY(0px)", backgroundColor : '#DB6262', zIndex : "10"}}>
            <div className={Styles.topSearch_contents}>
              <img src={Personality1} className={Styles.topSearch_charisma} />
              <div>
                <p>自分のタイプは？</p>
                <h4>実力No.1</h4>
              </div>
            </div>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp1()}>
              <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#DB6262'}} />
            </button>
            <button className={Styles.topSearch_arrow}>
            </button>
          </div>
          <div className={animate2 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
            style={animate2 ? {transform : "translateY(-80px)", backgroundColor : '#7764C2', zIndex : "9"} 
              : {transform : "translateY(0px)", backgroundColor : '#7764C2', zIndex : "9"}}>
            <div className={Styles.topSearch_contents}>
              <img src={Personality2} className={Styles.topSearch_charisma} />
              <div>
                <p>自分のタイプは？</p>
                <h4>スペシャリスト</h4>
              </div>
            </div>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp2()}>
              <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#7764C2'}} />
            </button>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown2()}>
              <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#7764C2'}} />
            </button>
          </div>
          <div className={animate3 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
            style={animate3 ? {transform : "translateY(-80px)", backgroundColor : '#769FCE', zIndex : "8"} 
              : {transform : "translateY(0px)", backgroundColor : '#769FCE', zIndex : "8"}}>
            <div className={Styles.topSearch_contents}>
              <img src={Personality3} className={Styles.topSearch_charisma} />
              <div>
                <p>自分のタイプは？</p>
                <h4>完璧主義</h4>
              </div>
            </div>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp3()}>
              <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#769FCE'}} />
            </button>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown3()}>
              <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#769FCE'}} />
            </button>
          </div>
          <div className={animate4 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
            style={animate4 ? {transform : "translateY(-80px)", backgroundColor : '#D4C145', zIndex : "7"} 
              : {transform : "translateY(0px)", backgroundColor : '#D4C145', zIndex : "7"}}>
            <div className={Styles.topSearch_contents}>
              <img src={Personality4} className={Styles.topSearch_charisma} />
              <div>
                <p>自分のタイプは？</p>
                <h4>カリスマ</h4>
              </div>
            </div>
            <button className={Styles.topSearch_arrow} style={{color : '#D4C145'}} onClick={() => handleAnimationUp4()}>
              <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} />
            </button>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown4()}>
              <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#D4C145'}} />
            </button>
          </div>
          <div className={animate5 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
            style={animate5 ? {transform : "translateY(-80px)", backgroundColor : '#428D60', zIndex : "6"} 
              : {transform : "translateY(0px)", backgroundColor : '#428D60', zIndex : "6"}}>
            <div className={Styles.topSearch_contents}>
              <img src={Personality5} className={Styles.topSearch_charisma} />
              <div>
                <p>自分のタイプは？</p>
                <h4>イノベーション</h4>
              </div>
            </div>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp5()}>
              <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#428D60'}} />
            </button>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown5()}>
              <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#428D60'}} />
            </button>
          </div>
          <div className={animate6 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
            style={animate6 ? {transform : "translateY(-80px)", backgroundColor : '#EC8CB4', zIndex : "5"} 
              : {transform : "translateY(0px)", backgroundColor : '#EC8CB4', zIndex : "5"}}>
            <div className={Styles.topSearch_contents}>
              <img src={Personality6} className={Styles.topSearch_charisma} />
              <div>
                <p>自分のタイプは？</p>
                <h4>トレンド創出</h4>
              </div>
            </div>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp6()}>
              <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#EC8CB4'}} />
            </button>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown6()}>
              <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#EC8CB4'}} />
            </button>
          </div>
          <div className={animate7 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
            style={animate7 ? {transform : "translateY(-80px)", backgroundColor : '#303030', zIndex : "4"} 
              : {transform : "translateY(0px)", backgroundColor : '#303030', zIndex : "4"}}>
            <div className={Styles.topSearch_contents}>
              <img src={Personality7} className={Styles.topSearch_charisma} />
              <div>
                <p>自分のタイプは？</p>
                <h4>独立成功型</h4>
              </div>
            </div>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp7()}>
              <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#303030'}} />
            </button>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown7()}>
              <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#303030'}} />
            </button>
          </div>
          <div className={animate8 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
            style={animate8 ? {transform : "translateY(-80px)", backgroundColor : '#BD8C57', zIndex : "3"} 
              : {transform : "translateY(0px)", backgroundColor : '#BD8C57', zIndex : "3"}}>
            <div className={Styles.topSearch_contents}>
              <img src={Personality8} className={Styles.topSearch_charisma} />
              <div>
                <p>自分のタイプは？</p>
                <h4>経営者タイプ</h4>
              </div>
            </div>
            <button className={Styles.topSearch_arrow}>
            </button>
            <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown8()} >
              <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#BD8C57'}} />
            </button>
          </div>
        </div>

      </div>
      
      <div className={Styles.links}>
        <h4 onClick={() => history.push('/company')}>求人を掲載する</h4>
        <div>
          <a href='https://apps.apple.com/jp/app/memorii-works/id1578278781'>
            <FaAppStoreIos className={Styles.links_icon} />
          </a>
        </div>
      </div>

      <div className={Styles.phone_filter}>
        <IoFilterOutline className={Styles.phone_filter_icon} />
      </div>
    </div>
    </>
  );
}

export default WorksTop;

{/* <div className={flip ? Styles.topSearch_flip_true : Styles.topSearch_flip_false}
  style={flip ? {transform : "rotateY(0)", backgroundColor : '#DB6262'} : 
  {transform : "rotateY(360deg)", backgroundColor : '#DB6262'}}>

  <div className={Styles.topSearch_hidden} style={flip ? {display : "block"} : {display : "none"}}>
    <div className={animate1 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
      style={animate1 ? {transform : "translateY(-80px)", backgroundColor : '#DB6262', zIndex : "10"} : 
        {transform : "translateY(0px)", backgroundColor : '#DB6262', zIndex : "10"}}>
      <p>性格<br />診断</p>
      <div className={Styles.topSearch_line}></div>
      <div className={Styles.topSearch_contents}>
        <img src={Personality1} className={Styles.topSearch_charisma} />
        <h4>実力No.1 タイプ</h4>
      </div>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp1()}>
        <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#DB6262'}} />
      </button>
      <button className={Styles.topSearch_arrow}>
      </button>
    </div>
    <div className={animate2 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
      style={animate2 ? {transform : "translateY(-80px)", backgroundColor : '#7764C2', zIndex : "9"} 
        : {transform : "translateY(0px)", backgroundColor : '#7764C2', zIndex : "9"}}>
      <p>性格<br />診断</p>
      <div className={Styles.topSearch_line}></div>
      <div className={Styles.topSearch_contents}>
        <img src={Personality2} className={Styles.topSearch_charisma} />
        <h4>スペシャリスト タイプ</h4>
      </div>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp2()}>
        <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#7764C2'}} />
      </button>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown2()}>
        <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#7764C2'}} />
      </button>
    </div>
    <div className={animate3 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
      style={animate3 ? {transform : "translateY(-80px)", backgroundColor : '#769FCE', zIndex : "8"} 
        : {transform : "translateY(0px)", backgroundColor : '#769FCE', zIndex : "8"}}>
      <p>性格<br />診断</p>
      <div className={Styles.topSearch_line}></div>
      <div className={Styles.topSearch_contents}>
        <img src={Personality3} className={Styles.topSearch_charisma} />
        <h4>完璧主義 タイプ</h4>
      </div>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp3()}>
        <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#769FCE'}} />
      </button>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown3()}>
        <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#769FCE'}} />
      </button>
    </div>
    <div className={animate4 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
      style={animate4 ? {transform : "translateY(-80px)", backgroundColor : '#D4C145', zIndex : "7"} 
        : {transform : "translateY(0px)", backgroundColor : '#D4C145', zIndex : "7"}}>
      <p>性格<br />診断</p>
      <div className={Styles.topSearch_line}></div>
      <div className={Styles.topSearch_contents}>
        <img src={Personality4} className={Styles.topSearch_charisma} />
        <h4>カリスマ タイプ</h4>
      </div>
      <button className={Styles.topSearch_arrow} style={{color : '#D4C145'}} onClick={() => handleAnimationUp4()}>
        <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} />
      </button>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown4()}>
        <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#D4C145'}} />
      </button>
    </div>
    <div className={animate5 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
      style={animate5 ? {transform : "translateY(-80px)", backgroundColor : '#428D60', zIndex : "6"} 
        : {transform : "translateY(0px)", backgroundColor : '#428D60', zIndex : "6"}}>
      <p>性格<br />診断</p>
      <div className={Styles.topSearch_line}></div>
      <div className={Styles.topSearch_contents}>
        <img src={Personality5} className={Styles.topSearch_charisma} />
        <h4>トレンド創出 タイプ</h4>
      </div>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp5()}>
        <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#428D60'}} />
      </button>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown5()}>
        <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#428D60'}} />
      </button>
    </div>
    <div className={animate6 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
      style={animate6 ? {transform : "translateY(-80px)", backgroundColor : '#EC8CB4', zIndex : "5"} 
        : {transform : "translateY(0px)", backgroundColor : '#EC8CB4', zIndex : "5"}}>
      <p>性格<br />診断</p>
      <div className={Styles.topSearch_line}></div>
      <div className={Styles.topSearch_contents}>
        <img src={Personality6} className={Styles.topSearch_charisma} />
        <h4>トレンド創出 タイプ</h4>
      </div>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp6()}>
        <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#EC8CB4'}} />
      </button>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown6()}>
        <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#EC8CB4'}} />
      </button>
    </div>
    <div className={animate7 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
      style={animate7 ? {transform : "translateY(-80px)", backgroundColor : '#303030', zIndex : "4"} 
        : {transform : "translateY(0px)", backgroundColor : '#303030', zIndex : "4"}}>
      <p>性格<br />診断</p>
      <div className={Styles.topSearch_line}></div>
      <div className={Styles.topSearch_contents}>
        <img src={Personality7} className={Styles.topSearch_charisma} />
        <h4>独立成功型 タイプ</h4>
      </div>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp7()}>
        <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#303030'}} />
      </button>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown7()}>
        <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#303030'}} />
      </button>
    </div>
    <div className={animate8 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
      style={animate8 ? {transform : "translateY(-80px)", backgroundColor : '#BD8C57', zIndex : "3"} 
        : {transform : "translateY(0px)", backgroundColor : '#BD8C57', zIndex : "3"}}>
      <p>性格<br />診断</p>
      <div className={Styles.topSearch_line}></div>
      <div className={Styles.topSearch_contents}>
        <img src={Personality8} className={Styles.topSearch_charisma} />
        <h4>経営者タイプ タイプ</h4>
      </div>
      <button className={Styles.topSearch_arrow}>
      </button>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown8()} >
        <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#BD8C57'}} />
      </button>
    </div>
  </div>

  <div className={Styles.topSearch_hidden} style={flip ? {display : "none"} : {display : "block"}}>
    <div className={animate2_1 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
      style={animate2_1 ? {transform : "translateY(-80px)", backgroundColor : '#DA6161', zIndex : "10"} : 
        {transform : "translateY(0px)", backgroundColor : '#DA6161', zIndex : "10"}}>
      <p>店舗<br />分類</p>
      <div className={Styles.topSearch_line}></div>
      <div className={Styles.topSearch_contents}>
        <img src={Salon1} className={Styles.topSearch_charisma} />
        <h4>有名 サロン</h4>
      </div>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp2_1()}>
        <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#DA6161'}} />
      </button>
      <button className={Styles.topSearch_arrow}>
      </button>
    </div>
    <div className={animate2_2 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
      style={animate2_2 ? {transform : "translateY(-80px)", backgroundColor : '#EB8BB3', zIndex : "9"} 
        : {transform : "translateY(0px)", backgroundColor : '#EB8BB3', zIndex : "9"}}>
      <p>店舗<br />分類</p>
      <div className={Styles.topSearch_line}></div>
      <div className={Styles.topSearch_contents}>
        <img src={Salon2} className={Styles.topSearch_charisma} />
        <h4>トレンド サロン</h4>
      </div>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp2_2()}>
        <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#EB8BB3'}} />
      </button>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown2_2()}>
        <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#EB8BB3'}} />
      </button>
    </div>
    <div className={animate2_3 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
      style={animate2_3 ? {transform : "translateY(-80px)", backgroundColor : '#7663BD', zIndex : "8"} 
        : {transform : "translateY(0px)", backgroundColor : '#7663BD', zIndex : "8"}}>
      <p>店舗<br />分類</p>
      <div className={Styles.topSearch_line}></div>
      <div className={Styles.topSearch_contents}>
        <img src={Salon3} className={Styles.topSearch_charisma} />
        <h4>おしゃれ サロン</h4>
      </div>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp2_3()}>
        <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#7663BD'}} />
      </button>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown2_3()}>
        <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#7663BD'}} />
      </button>
    </div>
    <div className={animate2_4 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
      style={animate2_4 ? {transform : "translateY(-80px)", backgroundColor : '#548B63', zIndex : "7"} 
        : {transform : "translateY(0px)", backgroundColor : '#548B63', zIndex : "7"}}>
      <p>店舗<br />分類</p>
      <div className={Styles.topSearch_line}></div>
      <div className={Styles.topSearch_contents}>
        <img src={Salon4} className={Styles.topSearch_charisma} />
        <h4>ビューティー サロン</h4>
      </div>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp2_4()}>
        <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#548B63'}} />
      </button>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown2_4()}>
        <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#548B63'}} />
      </button>
    </div>
    <div className={animate2_5 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
      style={animate2_5 ? {transform : "translateY(-80px)", backgroundColor : '#BB8A54', zIndex : "6"} 
        : {transform : "translateY(0px)", backgroundColor : '#BB8A54', zIndex : "6"}}>
      <p>店舗<br />分類</p>
      <div className={Styles.topSearch_line}></div>
      <div className={Styles.topSearch_contents}>
        <img src={Salon5} className={Styles.topSearch_charisma} />
        <h4>オールラウンド サロン</h4>
      </div>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp2_5()}>
        <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#BB8A54'}} />
      </button>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown2_5()}>
        <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#BB8A54'}} />
      </button>
    </div>
    <div className={animate2_6 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
      style={animate2_6 ? {transform : "translateY(-80px)", backgroundColor : '#7E9CC9', zIndex : "5"} 
        : {transform : "translateY(0px)", backgroundColor : '#7E9CC9', zIndex : "5"}}>
      <p>店舗<br />分類</p>
      <div className={Styles.topSearch_line}></div>
      <div className={Styles.topSearch_contents}>
        <img src={Salon6} className={Styles.topSearch_charisma} />
        <h4>愛され サロン</h4>
      </div>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp2_6()}>
        <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#7E9CC9'}} />
      </button>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown2_6()}>
        <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#7E9CC9'}} />
      </button>
    </div>
    <div className={animate2_7 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
      style={animate2_7 ? {transform : "translateY(-80px)", backgroundColor : '#CFC15A', zIndex : "4"} 
        : {transform : "translateY(0px)", backgroundColor : '#CFC15A', zIndex : "4"}}>
      <p>店舗<br />分類</p>
      <div className={Styles.topSearch_line}></div>
      <div className={Styles.topSearch_contents}>
        <img src={Salon7} className={Styles.topSearch_charisma} />
        <h4>密着 サロン</h4>
      </div>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationUp2_7()}>
        <FaLongArrowAltUp className={Styles.topSearch_arrow_icon} style={{color : '#CFC15A'}} />
      </button>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown2_7()}>
        <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#CFC15A'}} />
      </button>
    </div>
    <div className={animate2_8 ? Styles.topSearch_box_true : Styles.topSearch_box_false} 
      style={animate2_8 ? {transform : "translateY(-80px)", backgroundColor : '#313131', zIndex : "3"} 
        : {transform : "translateY(0px)", backgroundColor : '#313131', zIndex : "3"}}>
      <p>店舗<br />分類</p>
      <div className={Styles.topSearch_line}></div>
      <div className={Styles.topSearch_contents}>
        <img src={Salon8} className={Styles.topSearch_charisma} />
        <h4>こだわり サロン</h4>
      </div>
      <button className={Styles.topSearch_arrow}>
      </button>
      <button className={Styles.topSearch_arrow} onClick={() => handleAnimationDown2_8()}>
        <FaLongArrowAltDown className={Styles.topSearch_arrow_icon} style={{color : '#313131'}} />
      </button>
    </div>
  </div>
</div> */}

