import Styles from './styles.module.css';
import 'firebase/auth'
import React from 'react-router-dom';
import { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import DefaultTitle from './../package/parts/title';
import DefaultImage from './../../../image/works/default/home.png';
import Mail from './mail/index';
import { FaPencilAlt } from 'react-icons/fa';
import Invite from './invite/index';
import DefaultImg from './../../../image/works/default/account.png';
import Edit from './edit/index';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import DefaultInput1 from './../package/parts/1-input/index';

const MemoriiWorksSetting = (props) => {

  const [ name, setName ] = useState(props.companyList.name);
  const [ invite, setInvite ] = useState(false);
  const [ mail, setMail ] = useState(false);
  const [ banner, setBanner ] = useState(false);

  const [ styleImageFile, setStyleImageFile ] = useState('');
  const [ styleImageFilePath, setStyleImageFilePath ] = useState('');

  const [ member, setMember ] = useState([]);

  const [ manageId, setManageId ] = useState('');

  useEffect(() => {
    getAuthority(props.companyList.member);
  }, []);
  
  function getAuthority(data) {
    const docRef = collection(firebase.firestore(), 'users')
    const queryRef = query(docRef, where("user_uid", "in", data))
    const unsub = onSnapshot(queryRef, snapshot => {
      var tmp = [];
      snapshot.docs.forEach(doc => {
        tmp.push({...doc.data(), id: doc.id})
      });
      setMember(tmp);
    })
    return () => unsub();
  }

  const styleImages = e => {
    if (e.target.files[0]) {
      setStyleImageFile(URL.createObjectURL(e.target.files[0]));
      setStyleImageFilePath(e.target.files[0]);
    }
  }

  function save() {
    setBanner(true);

    firebase.firestore().collection('companies').doc(props.companyList.id)
    .update({
      name: name,
    });

    firebase.storage().ref().child(`/companies_logo/${props.companyList.id}`).put(styleImageFilePath);

    firebase.firestore().collection('recruits').where('companyId', '==', props.companyList.id).get()
    .then(snapShot => {
      snapShot.forEach((doc) => {
        firebase.firestore().collection('recruits').doc(doc.id)
        .update({
          companyName: name,
        });
      });
    });

    setTimeout(() => {
      setBanner(false);
    }, 1500);
  }
  
  return (
    <>
    <div className={Styles.box}>
      <div className={Styles.box_inline}>
        <div className={Styles.contents}>
          <h5 className={Styles.title}>基本情報</h5>

          <DefaultTitle title={'会社ロゴ'} required={false} />
          <div className={Styles.make_image}>
            <FaPencilAlt className={Styles.make_image_pen} />
            <input type='file' onChange={styleImages} />
            {styleImageFile === '' ?
              <img className={Styles.make_image_file}
              src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/companies_logo%2Fresize_images%2F${props.companyList.id}_500x500?alt=media&token=?${new Date().getTime()}`}
              onError={e => {
                e.target.onError = null;
                e.target.src = DefaultImage;
              }}/>
              :
              <img className={Styles.make_image_file} src={styleImageFile} />
            }
          </div>

          <DefaultInput1
            title={'企業名'} required={true} length={20} place={'memorii 株式会社'} type={'text'}
            value={name} setValue={setName}
            desc={'　'}
          />

          <button className={Styles.save} onClick={() => save()}>保存</button>
        </div>

        <div className={Styles.contents}>

          <h5 className={Styles.title}>メンバー管理</h5>
          <div className={Styles.member_title}>
            <p>メンバー</p>
            <h4>権限</h4>
          </div>

          {member.length !== 0 ?
          <>
            {member.map((data, index) =>
              <div className={Styles.account} key={index}>
                <div className={Styles.account_user}>
                  <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${data.id}_200x200?alt=media&token=?${new Date().getTime()}`}
                    onError={e => {
                      e.target.onError = null;
                      e.target.src = DefaultImg;
                    }}/>
                  <h4>{data.user_name}</h4>
                </div>
                <div className={Styles.account_authority}>
                  {props.companyList.administrator.includes(data.id) ? '管理者' : '審査員' }
                </div>
                <FaPencilAlt onClick={() => setManageId(data.id)} className={Styles.account_icon} />
              </div>
            )}
          </> : <></> }

          <div className={Styles.invite} onClick={() => setInvite(true)}>
            <FaPencilAlt className={Styles.invite_icons} />
            <p>メンバーを招待</p>
          </div>

          <div style={{height: '10px'}}></div>

          <div className={Styles.invite_btn}>
            <p>招待中アカウント</p>
          </div>

          {props.companyList.invite.length !== 0 ?
          <>
            <div className={Styles.invite_list}>
              {props.companyList.invite.map((data, index) =>
                <p>{data}</p>
              )}
            </div>
          </> : <></> }
        </div>

        <div className={Styles.contents}>

          <h5 className={Styles.title}>メール通知設定</h5>

          <div className={Styles.account}>
          
            <div className={Styles.account_user}>
              <h4>通知先アドレス</h4>
            </div>

            <div className={Styles.account_notification}>
              <p>{props.companyList.notification_mails === undefined ? "未登録": props.companyList.notification_mails}</p>
              <FaPencilAlt onClick={() => setMail(true)} className={Styles.account_icon} />
            </div>
            
          </div>

          <div style={{height : '30px'}}></div>

          <h5 className={Styles.title}>LINE通知設定</h5>

          <div className={Styles.account}>
            {props.userList.user_lineId === undefined ?
              <>
              <div className={Styles.account_user}>
                <img src={`https://linestep.jp/wp-content/uploads/2022/04/linelogo.png`}
                  onError={e => {
                    e.target.onError = null;
                    e.target.src = DefaultImg;
                  }}/>
                <h4>連携</h4>
              </div>

              <a href={`https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=2006500323&redirect_uri=https%3A%2F%2Fmemorii-works.com%2Fservice%2Fcallback&state=${props.companyList.id}&scope=openid%20profile&nonce=SWFEng3EK6E3HNKyIOa-YT8NW5QIrtqEjYpY2sZoLMI`}
                className={Styles.account_alignment}>
                <div>
                  LINEと連携する
                </div>
              </a>
              </>
              :
              <>
              <div className={Styles.account_user}>
                <img src={props.userList.user_linePicture}
                  onError={e => {
                    e.target.onError = null;
                    e.target.src = DefaultImg;
                  }}/>
                <h4>{props.userList.user_lineName}</h4>
              </div>

              <a href={`https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=2006500323&redirect_uri=https%3A%2F%2Fmemorii-works.com%2Fservice%2Fcallback&state=${props.companyList.id}&scope=openid%20profile&nonce=SWFEng3EK6E3HNKyIOa-YT8NW5QIrtqEjYpY2sZoLMI`}
                className={Styles.account_alignment}>
                <div>
                  LINEと連携済み
                </div>
              </a>

              </>
            }
          </div>

          <p className={Styles.desc_line}>※上記の連携が完了し次第、通知を行うための<a href='https://lin.ee/ApYAoTN'>公式LINE</a>を追加してください。</p>

          <a href='https://lin.ee/ApYAoTN'>
            <button className={Styles.btn_line}>通知用 公式LINE</button>
          </a>

        </div>

      </div>
    </div>

    {invite ?
      <Invite setInvite={setInvite} companyList={props.companyList} userList={props.userList} />
    : <></> }

    {mail ?
      <Mail mailAddress={props.companyList.notification_mails === undefined ? "" : props.companyList.notification_mails} setMail={setMail} companyList={props.companyList} userList={props.userList} />
    : <></> }

    {manageId !== '' ?
      <Edit manageId={manageId} setManageId={setManageId} companyList={props.companyList} userList={props.userList} check={props.companyList.administrator.length === 1} />
    : <></> }

    {banner ?
      <div className={Styles.banner}>
        保存しました。
      </div> : <></>
    }
    </>
  );
}

export default MemoriiWorksSetting;