import Styles from './styles.module.css';
import firebase from 'firebase/compat/app';
import { useState } from 'react';
import { FaBookmark } from "react-icons/fa";
import { FaRegBookmark } from "react-icons/fa6";
import { BiMessageDetail } from "react-icons/bi";
import Details from './../../../package/info/index';
import { useHistory } from 'react-router-dom';
import { LuClipboardEdit } from "react-icons/lu";
import MemoPanel from "./memo/index";
import Examination from "./../../../package/info/pc/evaluation";
import DefaultImg from './../../../../../image/works/default.png';

const MemoriiWorksManageItems = (props) => {

  const history = useHistory();

  const [ memo, setMemo ] = useState('');
  const [ examination, setExamination ] = useState([]);
  const [ examinationPanel, setExaminationPanel ] = useState([]);

  const [ recruitId, setRecruitId ] = useState('');
  const [ applicantId, setApplicantId ] = useState('');

  return (
    <>
    <div className={Styles.box}>

      <div className={Styles.box_1}
        onClick={() => { setRecruitId(props.data.recruitId); setApplicantId(props.data.id); props.setApplicantsList(props.data); }}>
        <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/profiles%2Fresize_images%2F${props.data.uid}_500x500?alt=media&token=`} alt=''
          onError={e => {
            e.target.onError = null;
            e.target.src = DefaultImg;
          }}/>
        {/* <img src={`https://firebasestorage.googleapis.com/v0/b/trankllc-com.appspot.com/o/resume%2F${props.data.uid}_${props.data.recruitId}?alt=media&token=`} alt='' */}
        <div className={Styles.box_1_post}>
          <div>
            <div style={{backgroundColor: '#192651'}}></div>
            <p>{props.data.examination === 1 ? '1次選考' : props.data.examination === 2 ? '2次選考' : props.data.examination === 3 ? '3次選考' : props.data.examination === 4 ? '4次選考' : props.data.examination === 5 ? '5次選考' : props.data.examination === 6 ? '採用' : '不採用'}</p>
          </div>
          <h2>{props.data.name}</h2>
        </div>
      </div>

      <p className={Styles.box_2} 
        onClick={() => { setRecruitId(props.data.recruitId); setApplicantId(props.data.id); props.setApplicantsList(props.data);}}>
        {props.data.sex}性
      </p>

      <p className={Styles.box_3} 
        onClick={() => { setRecruitId(props.data.recruitId); setApplicantId(props.data.id); props.setApplicantsList(props.data);}}>
        {new Date(props.data.time.seconds * 1000).getFullYear()}年
        {new Date(props.data.time.seconds * 1000).getMonth() + 1}月
        {new Date(props.data.time.seconds * 1000).getDate()}日
      </p>

      <p className={Styles.box_5} 
        onClick={() => { setRecruitId(props.data.recruitId); setApplicantId(props.data.id); props.setApplicantsList(props.data);}}>
        {props.data.hopeStore !== undefined ?
        <>
          {props.data.hopeStore[0]}
        </> : <></> }
      </p>

      <p className={Styles.box_4} onClick={() => setMemo([ props.data.id, props.data.name, props.data.managerMemo,  ])}>
        {props.data.managerMemo}
      </p>

      {props.data[`examList${props.data.examination}`] === undefined ? <></> :
      <>
        {props.data[`examList${props.data.examination}`].includes(props.userList.id) || 8 < props.data[`examList${props.data.examination}`].length ? <></> :
          <LuClipboardEdit className={Styles.box_6} style={{color : '#222222'}} alt='' onClick={() => setExamination(props.data)} />
        }
      </>
      }

      {/* {(props.data[`examList${props.data.examination}`] !== undefined && props.data[`examList${props.data.examination}`].includes(props.userList.id)) || (8 <= props.data[`examList${props.data.examination}`].length) ? <></> :
        <LuClipboardEdit className={Styles.box_6} style={{color : '#222222'}} alt='' onClick={() => setExamination(props.data)} />
      } */}

      {props.save ?
        <FaBookmark className={Styles.box_6} style={{color : '#AD0200'}} alt=''
          onClick={() => {
            firebase.firestore().collection('applicants').doc(props.data.id)
            .update({save: firebase.firestore.FieldValue.arrayRemove(props.userList.user_uid)});
          }}
        />
      : <></> }

      {!props.save ?
        <FaRegBookmark className={Styles.box_6} style={{color : '#222222'}} alt=''
          onClick={() => {
            firebase.firestore().collection('applicants').doc(props.data.id)
            .update({save: firebase.firestore.FieldValue.arrayUnion(props.userList.user_uid)});
          }}
        />
      : <></> }

      {props.companyList.administrator.includes(props.userList.id) ?
        <BiMessageDetail className={Styles.box_7}
          onClick={() => {history.push(`/service/id=${props.companyList.id}+page=6+search=${props.data.name}`);}}
        />
      : <></> }

    </div>

    {recruitId !== '' && applicantId !== '' ?
      <Details recruitId={recruitId} applicantId={applicantId} setRecruitId={setRecruitId} setApplicantId={setApplicantId} userList={props.userList} navigation={props.navigation} setHistory={props.setHistory} companyList={props.companyList} />
    : <></> }

    {examination.length !== 0 ?
      <div className={Styles.modal}>
        <div>
          <div className={Styles.modal_black} onClick={() => {setExamination([])}}></div>
          <div className={Styles.modal_contents}>
            <h4>審査しますか？</h4>
            <p>
              応募者1名に対して審査できる人数は8名までとなりますので、予め社内で審査担当者を検討してください。
            </p>
            <div>
              <button style={{backgroundColor : 'white', color : '#AD0200'}} onClick={() => setExamination([])}>キャンセル</button>
              <button style={{backgroundColor : '#AD0200', color : 'white'}} onClick={() => {setExaminationPanel(examination); setExamination([]);}}>審査する</button>
            </div>
          </div>
        </div>
      </div>
    : <></> }

    {memo.length !== 0 ?
      <MemoPanel memo={memo} setMemo={setMemo} />
    : <></> }

    {examinationPanel.length !== 0 ?
      <Examination applicantsList={examinationPanel} setApplicantsList={setExaminationPanel} userList={props.userList} />
    : <></> }
    </>
  );
}

export default MemoriiWorksManageItems;
