import Styles from './styles.module.css';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import firebase from 'firebase/compat/app';
import { FaChevronDown } from "react-icons/fa";
import { onSnapshot, collection, query, where, orderBy, } from 'firebase/firestore';
import { IoFilterOutline } from "react-icons/io5";

import Salon1 from "./../../../../image/works/salon/1.png";
import Salon2 from "./../../../../image/works/salon/2.png";
import Salon3 from "./../../../../image/works/salon/3.png";
import Salon4 from "./../../../../image/works/salon/4.png";
import Salon5 from "./../../../../image/works/salon/5.png";
import Salon6 from "./../../../../image/works/salon/6.png";
import Salon7 from "./../../../../image/works/salon/7.png";
import Salon8 from "./../../../../image/works/salon/8.png";

const WorksTop = (props) => {

  const history = useHistory();

  const [ visible1, setVisible1 ] = useState(false);
  const [ visible2, setVisible2 ] = useState(false);
  const [ position1, setPosition1 ] = useState({ top: 0, left: 0 });
  const [ position2, setPosition2 ] = useState({ top: 0, left: 0 });

  const buttonRef1 = useRef(null);
  const buttonRef2 = useRef(null);
  const divRef1 = useRef(null);
  const divRef2 = useRef(null);

  const [ checkFirst, setCheckFirst ] = useState(false);

  const [ modal, setModal ] = useState(false);

  const [ pref, setPref ] = useState("");

  useEffect(() => {

    if (!checkFirst) {
      search();
      setCheckFirst(true);
    }

    if (visible1 || visible2) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };

  }, [visible1, visible2]);

  const handleButtonClick1 = (event) => {
    const button = buttonRef1.current;
    if (button) {
      const rect = button.getBoundingClientRect();
      setPosition1({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
    setVisible1(!visible1);
  };

  const handleButtonClick2 = (event) => {
    const button = buttonRef2.current;
    if (button) {
      const rect = button.getBoundingClientRect();
      setPosition2({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
    setVisible2(!visible2);
  };

  const handleClickOutside = (event) => {
    if (
      (divRef1.current && !divRef1.current.contains(event.target) && buttonRef1.current && !buttonRef1.current.contains(event.target)) ||
      (divRef2.current && !divRef2.current.contains(event.target) && buttonRef2.current && !buttonRef2.current.contains(event.target))
    ) {
      setVisible1(false);
      setVisible2(false);
    }
  };

  const shuffleArray = (array) => {
    const cloneArray = [...array]

    for (let i = cloneArray.length - 1; i >= 0; i--) {
      let rand = Math.floor(Math.random() * (i + 1))
      // 配列の要素の順番を入れ替える
      let tmpStorage = cloneArray[i]
      cloneArray[i] = cloneArray[rand]
      cloneArray[rand] = tmpStorage
    }

    return cloneArray
  }

  var hashTags = [
    "カットが得意",
    "カラーが得意",
    "カッコイイ施術多め",
    "ナチュラルな施術多め",
    "トレンド感のある施術多め",
    "SNS研修あり",
    "自社アカデミーあり",
    "動画研修制度あり",
    "セミナー参加費補助",
    "ウィッグ代補助",
    "社会保険完備",
    "完全週休2日制",
    "残業月20時間以内",
  ]

  var サロン分類 = [
    "有名サロン",
    "トレンドサロン",
    "おしゃれサロン",
    "ビューティーサロン",
    "オールラウンドサロン",
    "密着サロン",
    "愛されサロン",
    "こだわりサロン",
  ]

  const [ hashTagList, setHashTagList ] = useState([]);

  function search() {

    props.setRecruitList([]);

    const recruitsRef = collection(firebase.firestore(), 'recruits')

    var queryList = query(recruitsRef);

    const queryRef = query(queryList, where('status', "==", 1))
    const unsub = onSnapshot(queryRef, snapshot => {
      var recruits = [];
      snapshot.docs.forEach(doc => {
        recruits.push({...doc.data(), id: doc.id});
      })
      props.setRecruitList(shuffleArray(recruits));
    })
    return () => unsub();
  }
  
  var 都道府県 = [
    "北海道",
    "青森県",
    "岩手県",
    "宮城県",
    "秋田県",
    "山形県",
    "福島県",
    "東京都",
    "茨城県",
    "栃木県",
    "群馬県",
    "埼玉県",
    "千葉県",
    "神奈川県",
    "新潟県",
    "富山県",
    "石川県",
    "福井県",
    "山梨県",
    "長野県",
    "岐阜県",
    "静岡県",
    "愛知県",
    "京都府",
    "大阪府",
    "三重県",
    "滋賀県",
    "兵庫県",
    "奈良県",
    "和歌山県",
    "鳥取県",
    "島根県",
    "岡山県",
    "広島県",
    "山口県",
    "徳島県",
    "香川県",
    "愛媛県",
    "高知県",
    "福岡県",
    "佐賀県",
    "長崎県",
    "大分県",
    "熊本県",
    "宮崎県",
    "鹿児島県",
    "沖縄県",
  ]

  function arrayControl(value) {

    var list = [];

    if (!hashTagList.includes(value)) {
      list = [ ...hashTagList, value ];
      search(list);
    } else {
      list = hashTagList.filter((item) => item !== value);
      if (都道府県.includes(value)) {
        setPref("");
      }
      search(list);
    }

    const recruitsRef = collection(firebase.firestore(), 'recruits')

    var queryList = query(recruitsRef);
    
    list.forEach((tag) => {
      queryList = query(queryList, where(`recruit_searchTags.${tag}`, "==", true));
    });

    const queryRef = query(queryList, where('status', "==", 1))
    const unsub = onSnapshot(queryRef, snapshot => {
      var recruits = [];
      snapshot.docs.forEach(doc => {
        recruits.push({...doc.data(), id: doc.id});
      })
      props.setRecruitList(shuffleArray(recruits));
    });
    
    setHashTagList(list);
    return () => unsub();
  }

  return (
    <>
    <div className={Styles.filter}>
      <div className={Styles.filter_items}>
        <div ref={buttonRef1} onClick={handleButtonClick1}>
          <h4>サロン分類</h4>
          <FaChevronDown className={Styles.filter_items_icon} />
        </div>
        <div ref={buttonRef2} onClick={handleButtonClick2}>
          <h4>都道府県</h4>
          <FaChevronDown className={Styles.filter_items_icon} />
        </div>
      </div>
      <div className={Styles.filter_items_line}></div>
      <div className={Styles.filter_hash}>
        {hashTags.map((data, index) =>
          <div style={hashTagList.includes(data) ? {border : ".5px solid #555555"} : {border : ".5px solid #cccccc"}}
            onClick={() => {arrayControl(data);}}>
            <h4>{data}</h4>
          </div>
        )}
      </div>

      <div className={Styles.phone_filter} onClick={() => setModal(true)}>
        <IoFilterOutline className={Styles.phone_filter_icon} />
      </div>
    </div>

    {visible1 && (
      <div
        ref={divRef1}
        style={{
          position: "absolute",
          top: position1.top,
          left: position1.left,
        }}
        className={Styles.salon}
      >
        <div style={hashTagList.includes(サロン分類[0]) ? {border : "1px solid #555555"} : {border : "1px solid #EBEBEB"}}
          onClick={() => {arrayControl(サロン分類[0]); setVisible1(false);}}>
          <img src={Salon1} />
          <h4>{サロン分類[0]}</h4>
        </div>
        <div style={hashTagList.includes(サロン分類[1]) ? {border : "1px solid #555555"} : {border : "1px solid #EBEBEB"}}
          onClick={() => {arrayControl(サロン分類[1]); setVisible1(false);}}>
          <img src={Salon2} />
          <h4>{サロン分類[1]}</h4>
        </div>
        <div style={hashTagList.includes(サロン分類[2]) ? {border : "1px solid #555555"} : {border : "1px solid #EBEBEB"}}
          onClick={() => {arrayControl(サロン分類[2]); setVisible1(false);}}>
          <img src={Salon3} />
          <h4>{サロン分類[2]}</h4>
        </div>
        <div style={hashTagList.includes(サロン分類[3]) ? {border : "1px solid #555555"} : {border : "1px solid #EBEBEB"}}
          onClick={() => {arrayControl(サロン分類[3]); setVisible1(false);}}>
          <img src={Salon4} />
          <h4>{サロン分類[3]}</h4>
        </div>
        <div style={hashTagList.includes(サロン分類[4]) ? {border : "1px solid #555555"} : {border : "1px solid #EBEBEB"}}
          onClick={() => {arrayControl(サロン分類[4]); setVisible1(false);}}>
          <img src={Salon5} />
          <h4>{サロン分類[4]}</h4>
        </div>
        <div style={hashTagList.includes(サロン分類[5]) ? {border : "1px solid #555555"} : {border : "1px solid #EBEBEB"}}
          onClick={() => {arrayControl(サロン分類[5]); setVisible1(false);}}>
          <img src={Salon6} />
          <h4>{サロン分類[5]}</h4>
        </div>
        <div style={hashTagList.includes(サロン分類[6]) ? {border : "1px solid #555555"} : {border : "1px solid #EBEBEB"}}
          onClick={() => {arrayControl(サロン分類[6]); setVisible1(false);}}>
          <img src={Salon7} />
          <h4>{サロン分類[6]}</h4>
        </div>
        <div style={hashTagList.includes(サロン分類[7]) ? {border : "1px solid #555555"} : {border : "1px solid #EBEBEB"}}
          onClick={() => {arrayControl(サロン分類[7]); setVisible1(false);}}>
          <img src={Salon8} />
          <h4>{サロン分類[7]}</h4>
        </div>
      </div>
    )}

    {visible2 && (
      <div
        ref={divRef2}
        style={{
          position: "absolute",
          top: position2.top,
          left: position2.left,
        }}
        className={Styles.area}
      >
        {都道府県.map((data, index) =>
          <div style={hashTagList.includes(data) ? {border : "1px solid #555555"} : {border : "1px solid #EBEBEB"}}
            onClick={() => {
              arrayControl(data); setVisible2(false);
            }}>
            <h4>{data}</h4>
          </div>
        )}
      </div>
    )}

    {modal ?
      <div className={Styles.box}>
        <div>
          <div className={Styles.box_black} onClick={() => setModal(false)}></div>
          <div className={Styles.box_error}>
            <h4>memorii works</h4>
            <p>アプリをダウンロードして、就活をもっとスムーズに。</p>
            <a href='https://apps.apple.com/jp/app/memorii-works/id1578278781'>
              <button onClick={() => setModal(false)}>App store</button>
            </a>
          </div>
        </div>
      </div>
    : <></> }

    {/* 
    {visible3 && (
      <div
        ref={divRef3}
        style={{
          position: "absolute",
          top: position3.top,
          left: position3.left,
        }}
        className={Styles.salary}
      >
        {給与.map((data, index) =>
          <div style={hashTagList.includes(data) ? {border : "1px solid #555555"} : {border : "1px solid #EBEBEB"}}
            onClick={() => {arrayControl(data); setVisible3(false);}}>
            <h4>{data}</h4>
          </div>
        )}
      </div>
    )} */}
    </>
  );
}

export default WorksTop;
